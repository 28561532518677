import { Link } from "react-router-dom";

import moment from "moment";

import { Header } from "../Components/Header";

/*
 * A page containing int18 privacy policy at /privacy-policy.
 */
export const PrivacyPolicy = () => {
  return (
    <div className="pb-4 bg-background min-h-page">
      <Header title="Privacy Notice" />
      <div className="px-8 space-y-2 text-base text-justify">
        <p className="flex justify-between pt-3 text-sm">
          <b>US-DOCS\145372061.1 08:54</b>
          <b>
            <i>Last Revised on {moment("2023-10-12").format("LL")}</i>
          </b>
        </p>
        <p>
          This Privacy Notice describes how Voltage Park, Inc. (“<b>we</b>”, “
          <b>us</b>”, “<b>our</b>”) collects, uses and discloses information
          about you when you use our websites{" "}
          <Link
            to={{ pathname: "http://voltagepark.com" }}
            className="hover:underline text-brand"
            target="_blank"
          >
            VoltagePark.com
          </Link>{" "}
          or{" "}
          <Link to="/" className="hover:underline text-brand">
            auctions.voltagepark.com
          </Link>
          , applications, services, tools and features, or otherwise interact
          with us (collectively, the “<b>Platform</b>”).
          <span>  </span>
          <span>
            For the purposes of this Privacy Notice, “<b>you</b>” and “
            <b>your</b>” means you as the user of the Platform. Please note that
            the Platform is designed for users in the United States only and is
            not intended for users located outside the United States.
          </span>
        </p>
        <p>
          Please read this Privacy Notice carefully.<span>  </span>By using or
          accessing the Platform, you agree to the collection, use, and
          disclosure of your information as described in this Privacy Notice.
          <span>  </span>If you do not agree to this Privacy Notice, please do
          not use or access the Platform.
        </p>
        <p className="pt-2">
          <b>1. CHANGES TO THIS PRIVACY NOTICE</b>
        </p>
        <p>
          We may modify this Privacy Notice from time to time, in which case we
          will update the “Last Updated” date at the top of this Privacy Notice.
          If we make material changes to how we use or disclose information we
          collect, we will use reasonable efforts to notify you (such as{" "}
          <span>
            by emailing you at the last email address you provided us, by
            posting notice of such changes on the Platform, or by other means
            consistent with applicable law) and will take additional steps as
            required by applicable law.
          </span>
          <span>  </span>If you do not agree to any updates to this Privacy
          Notice, please do not continue using or accessing the Platform.
        </p>
        <p className="pt-2">
          <b>2. COLLECTION AND USE OF INFORMATION</b>
        </p>
        <p>
          When you use or access the Platform, we collect certain categories of
          information about you from a variety of sources.
        </p>
        <p>
          <i>
            <b>Information You Provide to Us</b>
          </i>
        </p>
        <p>
          Some features of the Platform may require you to directly provide
          certain information about yourself. You may elect not to provide this
          information, but doing so may prevent you from using or accessing
          these features. Information that you directly submit through our
          Platform includes:
        </p>
        <ul className="ml-4 list-disc">
          <li>
            <b>Basic contact details</b>, such as name, and email. We use this
            information to create and maintain your account and provide the
            Platform, and to communicate with you.
          </li>
          <li>
            <b>Payment information</b>, such as bank account information and
            billing address. We use this information to process your payment and
            provide the Platform.
          </li>
          <li>
            <b>
              Any other information you choose to include in communications with
              us
            </b>
            , for example, when sending a message to us.
          </li>
        </ul>
        <p>
          <i>
            <b>Information Collected Automatically</b>
          </i>
        </p>
        <p>
          We may also use cookies or other similar technologies to automatically
          collect certain information about your interactions with the Platform.
          We collect and use this information to run analytics and better
          understand user interactions with the Platform. Such information
          includes:
        </p>
        <ul className="ml-4 list-disc">
          <li>
            <b>Information regarding your interaction with the Platform</b>,
            such as log data, date and time stamps, and clickstream data.
          </li>
        </ul>
        <p>
          Most browsers accept cookies automatically, but you may be able to
          control the way in which your devices permit the use of cookies. If
          you so choose, you may block or delete certain of our cookies from
          your browser; however, blocking or deleting cookies may cause some of
          the Platform, including any portal features and general functionality,
          to work incorrectly. Your{" "}
          <span>
            browser settings may also allow you to transmit a “Do Not Track”
            signal when you visit various websites. Like many websites,{" "}
          </span>
          our website is not designed to respond{" "}
          <span>
            to “Do Not Track” signals received from browsers. To learn more
            about “Do Not Track” signals, you can visit{" "}
          </span>
          <Link
            className="hover:underline text-brand"
            to={{ pathname: "http://www.allaboutdnt.com/" }}
            target="_blank"
          >
            http://www.allaboutdnt.com
          </Link>
          .
        </p>
        <p>
          To opt out of Google Analytics, click{" "}
          <Link
            className="hover:underline text-brand"
            to={{ pathname: "https://tools.google.com/dlpage/gaoptout" }}
            target="_blank"
          >
            here
          </Link>
          .
        </p>
        <p>
          <i>
            <b>Information Collected From Other Sources</b>
          </i>
        </p>
        <p>
          We may obtain information about you from outside sources, including
          information that we collect directly from third parties and
          information from third parties that you choose to share with us. Such
          information includes:
        </p>
        <ul className="ml-4 list-disc">
          <li>
            <b>Basic contact details</b>, such as name, and email, from Google
            when you use your Google account to access the Platform. We use this
            information to create and maintain your account and provide the
            Platform, and to communicate with you.
          </li>
        </ul>
        <p>
          Any information we receive from outside sources will be treated in
          accordance with this Privacy Notice. We are not responsible for the
          accuracy of the information provided to us by third parties and are
          not responsible for any third party’s policies or practices. For more
          information, see the section below,{" "}
          <i>Third Party Websites and Links</i>.
        </p>
        <p>
          In addition to the specific uses described above, we may use any of
          the above information to provide you with the Platform and to maintain
          our business relationship, including by enhancing the safety and
          security of our Platform (e.g., troubleshooting, data analysis,
          testing, system maintenance, and reporting), providing customer
          support, sending service and other non-marketing communications,
          monitoring and analyzing trends, conducting internal research and
          development, complying with applicable legal obligations, enforcing
          any applicable terms of service, and protecting the Platform, our
          rights, and the rights of our employees, users or other individuals.
        </p>
        <p className="pt-2">
          <b>3. DISCLOSURE OF YOUR INFORMATION</b>
        </p>
        <p>
          We may disclose your information for legitimate purposes subject to
          this Privacy Notice, including the following categories of third
          parties:
        </p>
        <ul className="ml-4 list-disc">
          <li>
            Our affiliates or others within our corporate group, to efficiently
            provide the Services.
          </li>
          <li>
            Vendors or other service providers who help us provide the Platform,
            including for system administration, cloud storage, security, web
            analytics, and payment networks.
          </li>
          <li>
            Professional advisors, such as auditors, law firms, or accounting
            firms.
          </li>
          <li>
            Third parties in connection with or anticipation of an asset sale,
            merger, bankruptcy, or other business transaction.
          </li>
        </ul>
        <p>
          We may also disclose your information as needed to comply with
          applicable law or any obligations thereunder or to cooperate with law
          enforcement, judicial orders, and regulatory inquiries, to enforce any
          applicable terms of service, and to ensure the safety{" "}
          <span>and security of our business, employees, and users.</span>
        </p>
        <p className="pt-2">
          <b>4. THIRD PARTY WEBSITES AND LINKS</b>
        </p>
        <p>
          We may provide links to third-party websites or platforms. If you
          follow links to sites or platforms that we do not control and are not
          affiliated with us, you should review the applicable privacy notice,
          policies and other terms.<span>  </span>We are not responsible for the
          privacy or security of, or information found on, these sites or
          platforms.{" "}
          <span>
            Information you provide on public or semi-public venues, such as
            third-party social networking platforms, may also be viewable by
            other users of the{" "}
          </span>
          Platform<span> and/or users of those third-party platforms </span>
          <span>without limitation as to its use</span>
          <span>.</span> Our inclusion of such links does not, by itself, imply
          any endorsement of the content on such platforms or of their owners or
          operators.
        </p>
        <p className="pt-2">
          <b>5. CHILDREN’S PRIVACY</b>
        </p>
        <p>
          Our Platform is not intended for children, and we do not seek or
          knowingly collect any <span>personal information</span> about
          children. If we become aware that we have unknowingly collected{" "}
          <span>information</span> about a child, in particular any child under
          13 years of age, we will make commercially reasonable efforts to
          delete such information from our database. If you are the parent or
          guardian of a child under 13 years of age who has provided us with{" "}
          <span>their personal information</span>, you may contact us using the
          below information to request that it be deleted.
        </p>
        <p className="pt-2">
          <b>6. DATA SECURITY AND RETENTION</b>
        </p>
        <p>
          Despite our reasonable efforts to protect your information, no
          security measures are impenetrable, and we cannot guarantee “perfect
          security.” Any information you send to us electronically, while using
          the Platform or otherwise interacting with us, may not be secure while
          in transit. We recommend that you do not use unsecure channels to send
          us sensitive or confidential information.
        </p>
        <p>
          We retain your information for as long as is reasonably necessary for
          the purposes specified in this Privacy Notice. When determining the
          length of time to retain your information, we consider various
          criteria, including whether we need the information to continue to
          provide you the Platform, resolve a dispute, enforce our contractual
          agreements, prevent harm, promote safety, security and integrity, or
          protect ourselves, including our rights, property or products.
        </p>
        <p className="pt-2">
          <b>7. CALIFORNIA PRIVACY RIGHTS</b>
        </p>
        <p>
          This section applies to you only if you are a California resident (“
          <b>resident</b>” or “<b>residents</b>”). For purposes of this section,
          references to “personal information” shall include “sensitive personal
          information,” as these terms are defined under the California Consumer
          Privacy Act (“<b>CCPA</b>”).
        </p>
        <p>
          <i>
            <b>Processing of Personal Information</b>
          </i>
        </p>
        <p>
          In the preceding 12 months, we collected and (where indicated below)
          disclosed for a business purpose the following categories of personal
          information about residents:
        </p>

        <table className="text-sm table-auto">
          <thead>
            <tr>
              <th align="center" className="p-1.5 border border-black">
                Category
              </th>
              <th align="center" className="p-1.5 border border-black">
                Categories of Recipients
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td valign="middle" className="p-3 border border-black">
                Identifiers such as name and e-mail address
              </td>
              <td valign="middle" className="p-3 border border-black">
                Vendors or other service providers who help us provide the
                Platform, including for system administration, cloud storage,
                security, web analytics, and payment networks
              </td>
            </tr>
            <tr>
              <td valign="middle" className="p-3 border border-black">
                Personal information categories listed in the California
                Customer Records statute such as name, bank account information
              </td>
              <td valign="middle" className="p-3 border border-black">
                Vendors or other service providers who help us provide the
                Platform, including for system administration, cloud storage,
                security, web analytics, and payment networks
              </td>
            </tr>
            <tr>
              <td valign="middle" className="p-3 border border-black">
                Commercial information such as records of products or services
                purchased
              </td>
              <td valign="middle" className="p-3 border border-black">
                Vendors or other service providers who help us provide the
                Platform, including for system administration, cloud storage,
                security, web analytics, and payment networks
              </td>
            </tr>
            <tr>
              <td valign="middle" className="p-3 border border-black">
                Internet or other similar network activity such as information
                regarding your interaction with the Platform
              </td>
              <td valign="middle" className="p-3 border border-black">
                Vendors or other service providers who help us provide the
                Platform, including for system administration, cloud storage,
                security, web analytics, and payment networks
              </td>
            </tr>
            <tr>
              <td valign="middle" className="p-3 border border-black">
                Professional or employment-related information such as employer
              </td>
              <td valign="middle" className="p-3 border border-black">
                Vendors or other service providers who help us provide the
                Platform, including for system administration, cloud storage,
                security, web analytics, and payment networks
              </td>
            </tr>
          </tbody>
        </table>

        <p>
          The specific business or commercial purposes for which we collect your
          personal information and the categories of sources from which we
          collect your personal information are described in the section above,{" "}
          <i>Collection and Use Your Information</i>.{" "}
          <span>
            We do not use or disclose any of your sensitive personal
            information.
          </span>{" "}
          The criteria we use to determine how long to retain your personal
          information is described in the section above,{" "}
          <i>Data Security and Retention</i>.
        </p>

        <p>
          We disclosed personal information over the preceding 12 months for the
          following business or commercial purposes:
        </p>

        <ul className="ml-4 list-disc">
          <li>
            to communicate with you, provide you with products and services,
            etc.
          </li>
          <li>to maintain and secure your account with us; and</li>
          <li>
            to process your payment, to provide you with products or services
            you have requested.
          </li>
        </ul>
        <p>
          <i>
            <b>Selling and/or Sharing of Personal Information</b>
          </i>
        </p>
        <p>
          <b>
            We do not “sell” or “share” (as those terms are defined under the
            CCPA) personal information, nor have we done so in the preceding 12
            months.{" "}
          </b>
          Further, we do not have actual knowledge that we “sell” or “share”
          personal information of residents under 16 years of age.
        </p>
        <p>
          <i>
            <b>Your California Privacy Rights</b>
          </i>
        </p>
        <p>
          As a California resident, you may have the rights listed below in
          relation to personal information that we have collected about you.
          However, these rights are not absolute, and in certain cases, we may
          decline your request as permitted by law, including in the event you
          reside in a state that does not currently grant you these rights.
        </p>

        <ul className="ml-4 list-disc">
          <li>
            <b>Right to Know</b>
            <i>.</i> You have a right to request the following information about
            our collection, use and disclosure of your personal information:
          </li>
          <ul className="ml-4 list-disc">
            <li>
              categories of personal information we have collected and disclosed
              for a business purpose;
            </li>
            <li>
              categories of sources from which we collected personal
              information;
            </li>
            <li>
              the business or commercial purposes for collecting personal
              information;
            </li>
            <li>
              categories of third parties to whom the personal information was
              disclosed for a business purpose; and
            </li>
            <li>specific pieces of personal information we have collected.</li>
          </ul>
          <li>
            <b>Right to Delete</b>
            <i>.</i> You have a right to request that we delete personal
            information we maintain about you.
          </li>
          <li>
            <b>Right to Correct</b>
            <i>.</i> You have a right to request that we correct inaccurate
            personal information we maintain about you.
          </li>
        </ul>
        <p>
          You may exercise any of these rights by contacting us using the
          information provided below. We will not discriminate against you for
          exercising any of these rights. We may need to collect information
          from you to verify your identity, such as your email address,
          government issued ID or date of birth, before providing a substantive
          response to the request. You may designate, in writing or through a
          power of attorney document, an authorized agent to make requests on
          your behalf to exercise your rights. Before accepting such a request
          from an agent, we will require that the agent provide proof you have
          authorized them to act on your behalf, and we may need you to verify
          your identity directly with us.
        </p>
        <p className="pt-2">
          <b>8. HOW TO CONTACT US</b>
        </p>
        <p>
          Should you have any questions about our privacy practices or this
          Privacy Notice, please email us at{" "}
          <Link
            to={{ pathname: "mailto:help@voltagepark.com" }}
            className="hover:underline text-brand"
            target="_blank"
          >
            help@voltagepark.com
          </Link>{" "}
          or contact us at{" "}
          <span>
            <i>161 W 25th Ave. Suite #202, San Mateo CA 94403.</i>
          </span>
        </p>
      </div>
    </div>
  );
};
