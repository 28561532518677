import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const scrollToId = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    // 👇 Will scroll smoothly to the top of the next section
    element.scrollIntoView({ behavior: "smooth" });
  }
};

/*
 * A page containing int18 Commercial Agreement at /commercial-agreement.
 */
export const CommercialAgreement = () => {
  const docs = [
    { uri: require("../docs/csa.pdf") }, // Local File
  ];

  return (
    <DocViewer
      documents={docs}
      pluginRenderers={DocViewerRenderers}
      config={{
        pdfVerticalScrollByDefault: true,
      }}
    />
  );
};
