import { Link } from "react-router-dom";

import { ContractIcon } from "../icons/ContractIcon";

/**
 * Agreement Component to redirect the user to agreement page
 */
export const AgreementComponent = () => (
  <Link
    data-testid="AGREEMENT_LINK"
    to="/commercial-agreement"
    className="flex items-center px-2 py-0.5 text-sm font-medium text-center text-secondaryText border rounded-lg bg-white truncate w-fit"
    target="_blank"
  >
    <ContractIcon className="mr-1 text-secondaryText" />
    View Agreement
  </Link>
);
