import { Link } from "react-router-dom";

import { PageType, getFilteredNavLink } from "./Navbar";

import { appVersion } from "../utils/env";

import { DiscordLogo } from "./icons/DiscordLogo";
import { TwitterLogoLegacy } from "./icons/TwitterLogoLegacy";

const MainPages: PageType[] = [
  {
    name: "How It Works",
    pathname:
      "https://docs.voltagepark.com/voltage-park-exchange/getting-started/how-it-works",
    visible: "always",
    external: true,
  },
  { name: "About", pathname: "/about", visible: "always" },
  { name: "Contact Us", pathname: "/contact-us", visible: "always" },
];

const SecondaryPages: PageType[] = [
  {
    name: "Terms of Service",
    pathname: "/terms-of-service",
    visible: "always",
  },
  { name: "Privacy Policy", pathname: "/privacy-policy", visible: "always" },
  {
    name: "Commercial Service Agreement",
    pathname: "/commercial-agreement",
    visible: "always",
  },
];

/**
 * This component handles the bottom footer that is at the bottom of every page in the app.
 */
export const Footer = () => {
  return (
    <footer className="py-2 bg-white border-background">
      <div className="flex flex-col justify-between px-4 lg:flex-row sm:px-6 lg:px-8">
        <div className="flex justify-center md:justify-start">
          {MainPages.map((page, index) =>
            getFilteredNavLink(page, undefined, index !== MainPages.length - 1),
          )}
          <span className="py-2 text-base md:text-sm">|</span>
          <span className="py-2 text-base md:text-sm">|</span>
          <Link
            to={{ pathname: "https://twitter.com/voltagepark" }}
            className="px-3 py-2"
            target="_blank"
          >
            <TwitterLogoLegacy className="w-5 h-5" />
          </Link>
          <span className="py-2 text-base md:text-sm">|</span>
          <Link
            to={{ pathname: "https://discord.gg/22yqpvj6Zc" }}
            className="px-3 py-2"
            target="_blank"
          >
            <DiscordLogo className="w-5 h-6" />
          </Link>
        </div>
        <div className="flex justify-center md:justify-start">
          {SecondaryPages.map((page, index) =>
            getFilteredNavLink(
              page,
              undefined,
              index !== SecondaryPages.length - 1,
            ),
          )}
          {appVersion && (
            <>
              <span className="py-2 text-base md:text-sm">|</span>
              <span className="flex items-center px-3 py-2 text-base truncate md:text-sm">
                {appVersion}
              </span>
            </>
          )}
          <span className="hidden py-2 text-base md:text-sm md:block">|</span>
          <span className="hidden px-3 py-2 text-base truncate md:text-sm md:block group">
            Copyright © {new Date().getFullYear()}{" "}
            <Link
              to={{ pathname: "http://voltagepark.com" }}
              className="group-hover:underline"
              target="_blank"
            >
              Voltage Park
            </Link>
            . All rights reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};
