import axios from "axios";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";

import { getValidDate } from "../utils/timeLengthFormatter";
import { queryClient } from "./queryClient";

import { getFilteredOrderBooks } from "../utils/orderbook";

import { UserKey } from "./user";
import { InstanceKey } from "./instance";

import { User } from "../types/user";
import { Instance } from "../types/instance";
import {
  Order,
  OrderBook,
  OrderBalance,
  FilteredOrderBook,
  PlaceOrderRequest,
  CreateOrderRequest,
  UpdateOrderRequest,
  DeleteOrderRequest,
  ActivateOrderRequest,
  OrderBalanceRequest,
} from "../types/order";
import { PageResponse } from "../types/generic";

enum OrderKey {
  /** query key to fetch all orders for admin */
  ORDER_LIST_ALL = "order-list-all",

  /** query key to fetch all orders for user */
  ORDER_LIST = "order-list",

  /** query key to fetch order book list */
  ORDERBOOK = "orderbook",

  /** query key to fetch all balance history for an order */
  ORDER_BALANCE_LIST = "order-balance-list",

  /** query key to download all orders */
  DOWNLOAD_ORDER_LIST = "download-order-list",

  /** query key to place a new order request */
  PLACE_ORDER = "place-order",

  /** query key to create a new order request */
  CREATE_ORDER = "create-order",

  /** query key to pay an order request */
  PAY_ORDER = "pay-order",

  /** query key to activate an order request */
  ACTIVATE_ORDER = "activate-order",

  /** query key to update an order */
  UPDATE_ORDER = "update-order",

  /** query key to delete an order */
  DELETE_ORDER = "delete-order",
}

/** retrieve the list of all orders from the server */
const getAllOrders = async () => {
  // make the api-server request
  const url = `${process.env.REACT_APP_API_URL}/v1/order?all=true`;
  const response = await axios.get(url);

  // transform the result to the proper shape
  const orders: Order[] | undefined = response.data?.orders?.map(
    (item: any) => {
      return {
        id: item.id,
        priority: item.priority ?? 0,
        name: item.name,
        numberOfGPUs: item.targetNumGPUs,
        maxPricePerGPU: !!item.maxPrice ? +item.maxPrice / 100 : 0,
        type: item.type,
        status: item.status,
        accountId: item.accountID,
        company: item.company ?? "",
        email: item.email,
        startAt: getValidDate(item.startedTime || item.estimatedStartTime),
        endAt: getValidDate(item.softEndTime || item.forcedEndTime),
      };
    },
  );
  return { data: orders || [] };
};

/** Get the list of all orders */
export const useGetAllOrders = () => {
  return useQuery<{ data: Order[] }, Error>(
    [OrderKey.ORDER_LIST_ALL],
    getAllOrders,
  );
};

/** retrieve the list of orders from the server */
const getOrders = async (accountId: string) => {
  // make the api-server request
  const url = `${process.env.REACT_APP_API_URL}/v1/order?accountID=${accountId}`;
  const response = await axios.get(url);

  // transform the result to the proper shape
  const orders: Order[] | undefined = response.data?.orders?.map(
    (item: any) => {
      return {
        id: item.id,
        priority: item.priority ?? 0,
        name: item.name,
        numberOfGPUs: item.targetNumGPUs,
        maxPricePerGPU: !!item.maxPrice ? +item.maxPrice / 100 : 0,
        type: item.type,
        status: item.status,
        accountId: item.accountID,
        company: item.company ?? "",
        email: item.email,
        startAt: getValidDate(item.startedTime || item.estimatedStartTime),
        endAt: getValidDate(item.softEndTime || item.forcedEndTime),
      };
    },
  );
  return { data: orders || [] };
};

/** Get the list of orders */
export const useGetOrders = (accountId: string) => {
  return useQuery<{ data: Order[] }, Error>([OrderKey.ORDER_LIST], () =>
    getOrders(accountId),
  );
};

/** retrieve the list of current prices from the server */
const getOrderbook = async (accountId?: string) => {
  // make the api-server request
  const url = `${process.env.REACT_APP_API_URL}/v1/orderbook`;
  const response = await axios.get(url);

  // transform the result to the proper shape
  const orderBooks: OrderBook[] | undefined = response.data?.orders?.map(
    (item: any) => {
      return {
        id: item.id,
        numberOfGPUs: item.targetNumGPUs,
        pricePerGPU: !!item.maxPrice ? +item.maxPrice / 100 : 0,
        status: item.status,
        accountId: item.accountID,
        startAt: getValidDate(item.startedTime || item.estimatedStartTime),
        endAt: getValidDate(item.softEndTime || item.forcedEndTime),
      };
    },
  );

  const filteredOrderBooks = getFilteredOrderBooks({
    orderBooks,
    accountId,
  });

  const totalNumberOfGPUs = !!response.data.inventoryTotal
    ? +response.data.inventoryTotal
    : 0;
  const unallocatedNumberOfGPUs =
    !!response.data.remainingInventory && +response.data.remainingInventory > 0
      ? +response.data.remainingInventory
      : 0;
  const allocatedNumberOfGPUs = totalNumberOfGPUs - unallocatedNumberOfGPUs;

  return {
    ...filteredOrderBooks,

    totalNumberOfGPUs,
    allocatedNumberOfGPUs,
    unallocatedNumberOfGPUs,

    currentPricePerGPU: !!response.data.currentPrice
      ? +response.data.currentPrice / 100
      : 0,
  };
};

/** Get the list of currentPrices */
export const useGetOrderbook = (accountId?: string) => {
  return useQuery<FilteredOrderBook, Error>([OrderKey.ORDERBOOK], () =>
    getOrderbook(accountId),
  );
};

/** retrieve the list of balance history for an order from the server */
export const getOrderBalances = async (params: OrderBalanceRequest) => {
  const queryObject: any = {};
  if (params.page) queryObject.page_token = params.page;
  if (params.pageSize) queryObject.page_size = params.pageSize;
  const queryParams = new URLSearchParams(queryObject);

  // make the api-server request
  const url = `${process.env.REACT_APP_API_URL}/v1/order/${params.id}/balance${
    queryParams.size > 0 ? `?${queryParams.toString()}` : ""
  }`;
  const response = await axios.get(url);

  // transform the result to the proper shape
  const balanceHistories: OrderBalance[] | undefined =
    response.data?.balance?.map((item: any) => {
      return {
        createdAt: getValidDate(item.createdAt),
        amount: !!item.amount ? +item.amount / 100 : 0,
      };
    });
  return {
    data: balanceHistories || [],

    nextPage: response.data?.nextPage || undefined,
    previousPage: response.data?.previousPage || undefined,

    pageSize: response.data?.pageSize || 0,

    currentPageCount: response.data?.currentPage || 1,
    totalPageCount: response.data?.pageCount || 1,
  };
};

/** Get the list of balance history for an order */
export const useGetOrderBalances = (
  params: Omit<OrderBalanceRequest, "page">,
) => {
  return useInfiniteQuery<{ data: OrderBalance[] } & PageResponse, Error>(
    [OrderKey.ORDER_BALANCE_LIST, params.id],
    ({ pageParam }) => getOrderBalances({ page: pageParam, ...params }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      getPreviousPageParam: (lastGroup) => lastGroup.previousPage,
      getNextPageParam: (lastGroup) => lastGroup.nextPage,
    },
  );
};

/* request a new order to be included in list */
export const placeOrder = async (params: PlaceOrderRequest) => {
  const url = `${process.env.REACT_APP_API_URL}/v1/order`;
  const body = {
    type: params.type,
    targetNumGPUs: params.numberOfGPUs,
    minNumGPUs: params.numberOfGPUs,
    maxPrice: params.maxPricePerGPU && Math.round(params.maxPricePerGPU * 100),
    accountID: params.accountId,
    ...(params.connectivity && {
      connectivity: params.connectivity,
    }),
  };
  const response = await axios.post(url, body);
  return { ...params, id: response.data?.id };
};

/** Place a new order */
export const usePlaceOrder = () => {
  const { mutate, error, isError, isSuccess, isLoading, data } = useMutation<
    PlaceOrderRequest & { id: string },
    Error,
    PlaceOrderRequest
  >([OrderKey.PLACE_ORDER], placeOrder);

  return { placeOrder: mutate, error, isError, isSuccess, isLoading, data };
};

/* request a new order to be created in list by admin */
export const createOrder = async (params: CreateOrderRequest) => {
  const url = `${process.env.REACT_APP_API_URL}/api/order/admin-create`;
  const body = {
    userID: params.userId,
    startAt: params.startAt,
    isPaid: params.isPaid,
    priority: params.priority,
    targetNumGPUs: params.numberOfGPUs,
    minNumGPUs: params.numberOfGPUs,
    maxPrice: params.maxPricePerGPU,
    accountID: params.accountId,
  };
  const response = await axios.post(url, body);
  return { ...params, id: response.data?.id };
};

/** Create a new order by admin */
export const useCreateOrder = () => {
  const { mutate, isError, isSuccess, isLoading, data } = useMutation<
    CreateOrderRequest & { id: string },
    Error,
    CreateOrderRequest
  >([OrderKey.CREATE_ORDER], createOrder);

  return { createOrder: mutate, isError, isSuccess, isLoading, data };
};

/** Send an activate order request to the server */
export const activateOrder = async (params: ActivateOrderRequest) => {
  params.unpause = true;
  const url = `${process.env.REACT_APP_API_URL}/v1/order/${params.id}`;
  await axios.patch(url, params);
  return params;
};

/** Activate an order */
export const useActivateOrder = () => {
  const { mutate, isError, isSuccess, isLoading } = useMutation<
    ActivateOrderRequest,
    Error,
    ActivateOrderRequest
  >([OrderKey.ACTIVATE_ORDER], activateOrder);

  return { activateOrder: mutate, isError, isSuccess, isLoading };
};

/** Send a update order request to the server */
const updateOrder = async (params: UpdateOrderRequest) => {
  const url = `${process.env.REACT_APP_API_URL}/v1/order/${params.id}`;
  const body = {
    type: params.type,
    name: params.name,
    targetNumGPUs: params.numberOfGPUs,
    minNumGPUs: params.numberOfGPUs,
    maxPrice: Math.round(params.maxPricePerGPU * 100),
  };
  await axios.patch(
    url,
    params.isAdmin ? { ...body, priority: params.priority } : body,
  );
  return params;
};

/** Update an order */
export const useUpdateOrder = () => {
  const { mutate, isError, isSuccess, isLoading } = useMutation<
    UpdateOrderRequest,
    Error,
    UpdateOrderRequest
  >([OrderKey.UPDATE_ORDER], updateOrder);

  return { updateOrder: mutate, isError, isSuccess, isLoading };
};

/** Send a delete order request to the server */
const deleteOrder = async (params: DeleteOrderRequest) => {
  const url = `${process.env.REACT_APP_API_URL}/v1/order/${params.id}`;
  await axios.delete(url);
  return params;
};

/** Delete an order */
export const useDeleteOrder = () => {
  const { mutate, isError, isSuccess, isLoading } = useMutation<
    DeleteOrderRequest,
    Error,
    DeleteOrderRequest
  >([OrderKey.DELETE_ORDER], deleteOrder);

  return { deleteOrder: mutate, isError, isSuccess, isLoading };
};

/** Checks if atleast one id is present in the list */
const checkIfIdIsPresentInList = (
  list?: { id: string }[],
  orderIds?: string[],
) => {
  if (!orderIds || !list || orderIds.length === 0 || list.length === 0) {
    return false;
  }

  return list.some(({ id }) => orderIds.includes(id));
};

/** Refetch orders list */
export const refetchOrders = (
  orderIds?: string[],
  additionalProps?: {
    refetchType?: "active" | "all";
    refetchInstances?: boolean;
    redirectPage?: string;
  },
) => {
  const {
    redirectPage,
    refetchType = "all",
    refetchInstances = false,
  } = additionalProps ?? {};

  // If orderIds is not passed or is empty, refetch the order queries
  // And conditionally refetch instances and details based on passed additional flags
  if (!orderIds || orderIds.length === 0) {
    queryClient.invalidateQueries({
      queryKey: [OrderKey.ORDER_LIST],
      refetchType,
    });
    queryClient.invalidateQueries({
      queryKey: [OrderKey.ORDER_LIST_ALL],
      refetchType,
    });
    queryClient.invalidateQueries({
      queryKey: [OrderKey.ORDER_BALANCE_LIST],
      refetchType,
    });

    if (refetchInstances) {
      queryClient.invalidateQueries({
        queryKey: [InstanceKey.INSTANCE_LIST],
        refetchType,
      });
      queryClient.invalidateQueries({
        queryKey: [InstanceKey.INSTANCE_LIST_ALL],
        refetchType,
      });
      queryClient.invalidateQueries({
        queryKey: [InstanceKey.INSTANCE_MACHINE_LIST],
        refetchType,
      });
    }

    // If user if not fetched or fetched info is not admin, redirect to the passed page if available
    const userInfo: User | undefined = queryClient.getQueryData([
      UserKey.USER_INFO,
    ]);
    if ((!userInfo || userInfo.type !== "admin") && !!redirectPage) {
      window.location.href = redirectPage;
    }
    return;
  }

  // If any orderId is passed and present in cached orders, refetch the order queries
  const orders: { data: Order[] } | undefined = queryClient.getQueryData([
    OrderKey.ORDER_LIST,
  ]);
  const isIdPresentInOrders = checkIfIdIsPresentInList(orders?.data, orderIds);
  if (isIdPresentInOrders) {
    queryClient.invalidateQueries({
      queryKey: [OrderKey.ORDER_LIST],
      refetchType,
    });
  }

  // If any orderId is passed and present in cached all orders, refetch the all order queries
  const allOrders: { data: Order[] } | undefined = queryClient.getQueryData([
    OrderKey.ORDER_LIST_ALL,
  ]);
  const isIdPresentInAllOrders = checkIfIdIsPresentInList(
    allOrders?.data,
    orderIds,
  );
  if (isIdPresentInAllOrders) {
    queryClient.invalidateQueries({
      queryKey: [OrderKey.ORDER_LIST_ALL],
      refetchType,
    });
  }

  // Conditionally refetch instances if flag is present
  if (refetchInstances) {
    // If any orderId is passed and present in cached instances, refetch the instance queries
    const instances: { data: Instance[] } | undefined =
      queryClient.getQueryData([InstanceKey.INSTANCE_LIST]);
    const isIdPresentInInstances = checkIfIdIsPresentInList(
      instances?.data,
      orderIds,
    );
    if (isIdPresentInInstances) {
      queryClient.invalidateQueries({
        queryKey: [InstanceKey.INSTANCE_LIST],
        refetchType,
      });
    }

    // If any orderId is passed and present in cached all instances, refetch the all instance queries
    const allInstances: { data: Instance[] } | undefined =
      queryClient.getQueryData([InstanceKey.INSTANCE_LIST_ALL]);
    const isIdPresentInAllInstances = checkIfIdIsPresentInList(
      allInstances?.data,
      orderIds,
    );
    if (isIdPresentInAllInstances) {
      queryClient.invalidateQueries({
        queryKey: [InstanceKey.INSTANCE_LIST_ALL],
        refetchType,
      });
    }

    orderIds.forEach((orderId) => {
      queryClient.invalidateQueries({
        queryKey: [OrderKey.ORDER_BALANCE_LIST, orderId],
        refetchType,
      });
      queryClient.invalidateQueries({
        queryKey: [InstanceKey.INSTANCE_MACHINE_LIST, orderId],
        refetchType,
      });
    });
  }

  // If user if not fetched or fetched info is not admin, redirect to the passed page if available
  const userInfo: User | undefined = queryClient.getQueryData([
    UserKey.USER_INFO,
  ]);
  if ((!userInfo || userInfo.type !== "admin") && !!redirectPage) {
    window.location.href = redirectPage;
  }
};

/** Refetch current prices list */
export const refetchOrderbook = () => {
  queryClient.invalidateQueries([OrderKey.ORDERBOOK]);
};
