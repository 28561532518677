type VPAuctionsIconProps = {
  className?: string;
  brandClassName?: string;
  textClassName?: string;
};

export const VPAuctionsIcon = ({}: VPAuctionsIconProps) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="120" height="120" fill="black" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M59.6189 78.8462L33.2961 33.2541H39.6612L51.5821 53.902L56.348 45.6486L44.4263 25.0004H19L59.6189 95.354L79.3733 61.1385H69.8427L59.6189 78.8462Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M77.6413 47.2188H71.2766L79.3388 33.2541H85.7039L77.6413 47.2188ZM66.0684 39.4236L54.5268 59.4145L59.3811 67.8218L66.422 55.6261H69.4005H82.3177L100 25H74.2783L71.2766 30.3376L66.0684 39.4236Z"
      fill="white"
    />
  </svg>
);
