import { Link, useLocation } from "react-router-dom";

import { DashboardPathnames } from "../../pages/Dashboard";

export const OrderConfirmationDescription = () => {
  const { pathname } = useLocation();
  const isAdmin = pathname === DashboardPathnames.orders;

  if (isAdmin) {
    return <></>;
  }

  return (
    <div className="flex flex-col mb-4 text-sm text-tertiaryText">
      <span className="pb-2">
        By clicking “Confirm” you are agreeing to place an order for compute
        identified above and are acknowledging that you have read and agree to
        the{" "}
        <Link
          to="/commercial-agreement"
          className="hover:underline text-brand"
          target="_blank"
        >
          Compute Services Agreement
        </Link>{" "}
        and the{" "}
        <Link
          to="/terms-of-service"
          className="hover:underline text-brand"
          target="_blank"
        >
          Voltage Park Online Compute Terms of Service
        </Link>
        .
      </span>
      <span>
        For further details or clarifications please email:{" "}
        <Link
          to={{ pathname: "mailto:help@voltagepark.com" }}
          className="hover:underline text-brand"
          target="_blank"
        >
          help@voltagepark.com
        </Link>
      </span>
    </div>
  );
};
