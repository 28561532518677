import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

/*
 * A page containing int18 Terms of Service at /terms-of-service.
 */
export const TermsOfService = () => {
  const docs = [
    { uri: require("../docs/tos.pdf") }, // Local File
  ];

  return (
    <DocViewer
      documents={docs}
      pluginRenderers={DocViewerRenderers}
      config={{
        pdfVerticalScrollByDefault: true,
      }}
    />
  );
};
